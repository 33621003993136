<template>
  <section
    class="flex"
    style="flex-direction: column;"
  >
    <div
      class="thumb-photo mb-8"
      :style="getThumb"
    />
    <div class="flex align-items-center justify-center parmigiano text-titanic">
      {{ date }} {{ time }} Камера {{ item.screenshot.channel.name }}
    </div>
  </section>
</template>

<script>
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();
export default {
  name: 'ModalScreenshot',
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    thumb: null,
    date: null,
    time: null
  }),
  computed: {
    getThumb() {
      return {
        backgroundImage: `url("${this.thumb}")`
      };
    }
  },
  async mounted() {
    const { data: status } = await api.getScreenshotSensorCam(`/trassir/rest/scr/${this.item.screenshot.id}/status`);

    if (status.status === 'COMPLETED') {
      this.thumb = `${process.env.VUE_APP_API_SERVER}/trassir/rest/scr/${this.item.screenshot.id}`;
    }
    const date = new Date(this.item.timestamp * 1000);
    this.date = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}.${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}.${date.getUTCFullYear()}`;
    this.time = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
  }
};
</script>

<style lang="scss">
.thumb-photo{
  width: 774px;
  aspect-ratio: 16 / 10;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
