<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <r-button-action
      title="Назад"
      icon="arrow-left"
      color="rocky"
      size="sulguni"
      @click="linkAdmin()"
      class="mb-7"
    />
    <loader v-if="isLoading" />
    <div v-else>
      <div>
        <h1
          class="ricotta"
          style="overflow: auto;"
        >
          Новая точка сбора ТКО
        </h1>
      </div>
      <div>
        <div class="header-link">
          <div
            :class="information ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="informationFunc()"
          >
            Общая информация
            <div :class="information ? 'line' : ''" />
          </div>
          <div
            v-if="isView === 'video'"
            :class="observation ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="observationFunc()"
          >
            Наблюдение
            <div :class="observation ? 'line' : ''" />
          </div>
         <!-- <div
            :class="observation ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="observationFunc()"
          >
            Наблюдение
            <div :class="observation ? 'line' : ''" />
          </div> -->
          <!--  <div
             :class="sensor ? `mr-4 float link-information` : `mr-4 float link-information street`"
             @click="sensorFunc()">
             Датчик
             <div :class="sensor ? 'line' : ''">
             </div>
           </div> -->
        </div>
      </div>
      <loader v-if="isLoading" />
      <div
        v-else
        :key="countInput"
      >
        <div class="mt-6 flex flex-1">
          <div
            class="flex flex-1"
            style="flex-direction: column;"
          >
            <div v-show="information">
              <r-select
                v-model="items['type']"
                :items="$store.state.garbageTypes"
                label="Тип"
                class="flex-1"
                @input="chengeType(items['type'])"
              />
              <r-select
                :disabled="items['type'] !== 5 && items['type'] !== 6"
                label="Виды мусора"
                v-model="model"
                :items="items['type'] === 5 ? sortType : sortTypeCollection"
                class="flex-1 mt-5 opacity"
                @input="chenge"
                return-object
              />
              <div>
                <r-input
                  class="flex-2 mt-5  time-days float margin-bottom"
                  :value="''"
                  v-model="items['volume']"
                  label="Объем м³"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                />
                <r-input
                  class="flex-2 mt-5 ml-5 time-days float margin-bottom"
                  value=""
                  v-model="items['amount']"
                  label="Количество"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                />
              </div>
              <h2 class="taleggio ">
                График вывоза ТКО
              </h2>

              <div class="flex">
                <r-select
                  label="Дни"
                  v-model="modelDays"
                  :items="sortDay"
                  class="flex-1 mt-5 opacity"
                  @input="chengeDay"
                  return-object
                  :disabled="items.schedule.length > 0"
                />
              </div>
              <div class="flex mt-5">
                <r-time-picker
                  v-model="timeTo"
                  label="Время от"
                  class="flex-1 mr-5"
                  :disabled="items.schedule.length > 0"
                  :isClearModel="true"
                />
                <r-time-picker
                  v-model="timeFrom"
                  class="flex-1"
                  label="Время до"
                  :disabled="items.schedule.length > 0"

                />
              </div>
              <r-tooltip
                position="center-right"
                :is-arrow="false"
                align-title="end"
                max-width="200px"
                title="Вы можете указать дату и время графика вызова в свободной форме. Тогда поля &quot;Дни&quot; и &quot;Время вывоза&quot; не учитываются."
              >
                <template #activator>
                  <r-input
                    after-icon="help"
                    class="flex-1 mt-5 mb-10 float custom other"
                    :style="time ? 'margin-top: -140px;' : ''"
                    v-model="items.schedule"
                    label="Другое"
                    :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  />
                </template>
              </r-tooltip>

              <h2
                class="taleggio mb-5"
                :style="time ? 'margin-top: -60px;' : ''"
              >
                Площадка
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                :style="time ? 'margin-top: -16px;' : ''"
                value=""
                v-model="items['coverage']"
                label="Используемое покрытие"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 custom mb-5"
                value=""
                v-model="items['square']"
                label="Площадь м2"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <div
                class="flex mb-10"
                align-center
              >
                <r-checkbox
                  :value="isCheck ? '' : []"
                  class="mt-2"
                  title="Ограждение"
                  @input="funcCheck"
                />

                <r-input
                  class="flex-1 ml-5 opacity"
                  value=""
                  v-model="items['fence']"
                  label="Материал и тип ограждения"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  :readonly="!isCheck"
                />
              </div>

              <h2 class="taleggio mb-5">
                Владеющая организация
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['owner']"
                label="Название"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['orgn']"
                label="ОГРН"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom mb-10 "
                value=""
                v-model="items['owner_address']"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <h2 class="taleggio mb-5">
                Объект капитального строительства территории
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['property_type']"
                label="Тип"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom"
                value=""
                v-model="items['property_address']"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <div v-show="observation && isView === 'video'">
             <r-select
                :value="null"
                class="flex-1 opacity"
                label="Камера"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <!-- <div v-show="sensor" @click="SearchSensor">
              <rir-icon icon="search" fill="rocky" class="float mr-2"/>
              <div class="search">
                Найти ближайшие датчики
              </div>
            </div> -->
          </div>

          <div class="ml-6 flex-1 overflow-hidden">
            <div class="work-form__map">
              <rir-map
                style="height: 522px;"
                :center="coordinates"
                collapse-btn
                :zoom="18"
              >
                <ymap-marker
                  :coords="coordinates"
                  :marker-id="'marker'"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: true
                  }"
                  :icon="getMapIcons(items.type).markerIcon"
                  @dragend="onDragMarker"
                />
              </rir-map>

              <!--<div class="message" v-if="information">
              Чтобы изменить положение на карте, перетащите точку
            </div>-->
              <div class=" margin-info">
                <r-input
                  v-if="information"
                  v-model="items['address']"
                  value=""
                  label="Адрес"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  @keyup="onCoordinates(items['address'])"
                >
                </r-input>
              </div>
            </div>
          </div>
        </div>
        <div class="button_container">
          <r-button
            class="flex-1"
            :disabled="idType < 1"
            @click="save(items)"
            width="wide"
            title="Сохранить"
          />
        </div>
      </div>
    </div>
    <search-sensor v-if="isView === 'analytics'" ref="SearchSensor" />
    <search-not-view-sensor v-if="isView === 'video'" ref="SearchNotViewSensor" />
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import vClickOutside from 'v-click-outside';
import RirMap from '@/components/RirMap.vue';
import Select from '@/components/Select.vue';
import SelectType from '@/components/SelectType.vue';
import SearchSensor from '@/components/SearchSensor.vue';
import Loader from '@/components/Loader.vue';
import SearchNotViewSensor from '@/components/SearchNotViewSensor.vue';
import PlaceApi from '@/api/PlaceApi';

Vue.use(vClickOutside);

export default {
  components: { Loader, RirMap, Select, SelectType, SearchSensor, SearchNotViewSensor },
  props: {},
  data() {
    return {
      isView: false,
      model: [],
      isActive: Boolean,
      items: {
        schedule: '',
        lan: '',
        lng: '',
        type: 0,
        schedule: '',
        address: '',
        amount: '',
        coverage: '',
        fence: '',
        square: '',
        owner: '',
        orgn: '',
        volume: '',
        owner_address: '',
        property_type: '',
        property_address: '',
        extraData: {
          garbageType: '',
          clock: '',
          cub: '',
          days: '',
          another: ''
        }
      },
      isLoad: false,
      coordinates: [],
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      address: null,
      information: true,
      observation: false,
      sensor: false,
      time: false,
      timeTo: '00:00',
      timeFrom: '00:00',
      changeTime: [],
      count: 0,
      countInput: 0,
      countValue: 0,
      itemsCount: null,
      sort: [
        {
          title: 'Контейнер',
          id: 1,
          icon: 'tko'
        },
        {
          title: 'Площадка ТКО',
          id: 2,
          icon: 'tko-place'
        },
        {
          title: 'Бункер-накопитель',
          id: 3,
          icon: 'waste-bin'
        },
        {
          title: 'Мусорокамера',
          id: 4,
          icon: 'waste-camera'
        },
        {
          title: 'Раздельный сбор',
          id: 5,
          icon: 'separate'
        }
      ],
      sortType: [
        {
          title: 'Бумага',
          id: 1
        },
        {
          title: 'Стекло',
          id: 2
        },
        {
          title: 'Металл',
          id: 3
        },
        {
          title: 'PET пластик',
          id: 4
        },
        {
          title: 'Другое',
          id: 5
        }
      ],
      sortTypeCollection: [
        {
          title: 'Ртутьсодержащие лампы ',
          id: 6
        },
        {
          title: 'Ртутные градусники',
          id: 7
        },
        {
          title: 'Батарейки',
          id: 8
        },
        {
          title: 'Аккумуляторы',
          id: 9
        },
        {
          title: 'Технические масла',
          id: 10
        }
      ],
      sortDay: [
        {
          title: 'Пн',
          id: 1
        },
        {
          title: 'Вт',
          id: 2
        },
        {
          title: 'Ср',
          id: 3
        },
        {
          title: 'Чт',
          id: 4
        },
        {
          title: 'Пт',
          id: 5
        },
        {
          title: 'Сб',
          id: 6
        },
        {
          title: 'Вс',
          id: 7
        }
      ],
      modelDays: [],
      isCheck: false,
      typeName: '',
      idType: 0,
      idTypes: [],
      flyButton: false,
      emptyFormData: {}
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    }
  },
  async created() {
    this.emptyFormData = {
      ...this.items
    };
    const response = await new PlaceApi().systemOptions();
    let isView = response?.ntechlabConfig?.view;
    this.isView = isView == 'analytics' || isView == 'video' ? isView : false;
  },
  activated() {
    this.resetData();
    this.count++;
  },
  async deactivated() {
    this.resetData();
  },
  mounted() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },

  methods: {
    linkAdmin() {
      const r = this.$router.resolve({
        name: 'index',
        params: {},
        query: {
          tab: this.$route?.query?.tab,
          point: this.$route?.query?.point,
          type: this.$route?.query?.type
        }
      });

      window.location.assign(r.href);
    },
    getMapIcons(type, color = '4480F3') {
      const icons = {
        1: {
          markerIcon: this.$markerIcon('markerIcon', color)
        },
        2: {
          markerIcon: this.$markerIcon('tko', color)
        },
        3: {
          markerIcon: this.$markerIcon('wasteBin', color)
        },
        4: {
          markerIcon: this.$markerIcon('wasteCam', color)
        },
        5: {
          markerIcon: this.$markerIcon('separate', color)
        }
      };
      return icons[type] || {
        markerIcon: this.$markerIcon()
      };
    },
    resetData() {
      this.items = JSON.parse(JSON.stringify(this.emptyFormData));
      this.modelDays = [];
      this.idTypes = [];
      this.idType = 0;
      this.loadVideo = [];
      this.model = [];
      this.selectCamera = null;
      this.svgIcon = null;
      this.initialData = null;
      this.isSave = true;
      this.flyButton = false;
      this.camera = [];
      this.isLoad = false;
      this.coordinates = [];
      this.isButton = true;
      this.isCovid = Boolean;
      this.value = '';
      this.coors = false;
      this.address = null;
      this.information = true;
      this.observation = false;
      this.sensor = false;
      this.time = false;
      this.timeTo = '0:00';
      this.timeFrom = '0:00';
      this.changeTime = [];
      this.count = 0;
      this.countInput = 0;
      this.countValue = 0;
      this.itemsCount = null;
      this.isCheck = false;
      this.typeName = '';
      this.coordinates = this.$cityCenter;
    },
    chengeType(id) {
      this.idType = id;
      this.countValue++;
    },
    chenge(val) {
      this.idTypes = val;
    },
    chengeDay(val) {
    },
    funcCheck() {
      this.isCheck = !this.isCheck;
      if (this.isCheck === false) {
        this.items.fence = '';
        this.items.square = '';
      }
    },
    SearchSensor() {
      if (this.isView) {
        this.$refs.SearchSensor.showSensor();
      } else {
        this.$refs.SearchNotViewSensor.showSensor();
      }
    },
    informationFunc() {
      this.information = true;
      this.observation = false;
      this.sensor = false;
    },
    observationFunc() {
      this.information = false;
      this.observation = true;
      this.sensor = false;
    },
    sensorFunc() {
      this.information = false;
      this.observation = false;
      this.sensor = true;
    },
    onClickOutside(event) {
      this.time = false;
    },
    setHour(n) {
      const date = new Date(+this.model);
      this.model = date.setHours(n);
      // this.$emit('input', date.setHours(n))
    },
    setMinute(n) {
      const date = new Date(+this.model);
      this.model = date.setMinutes(n);
      // this.$emit('input', date.setMinutes(n))
    },
    hourAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const hour = document.querySelectorAll(`div.hour${i}`)[0];
        if (item === i) {
          hour.style.background = '#3D75E4';
          hour.style.color = '#FFFFFF';
          hour.style.borderRadius = '5px';
        } else {
          hour.style.background = '';
          hour.style.color = '';
        }
      }
    },
    minutesAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const minutes = document.querySelectorAll(`div.minutes${i}`)[0];
        if (item === i) {
          minutes.style.background = '#3D75E4';
          minutes.style.color = '#FFFFFF';
          minutes.style.borderRadius = '5px';
        } else {
          minutes.style.background = '';
          minutes.style.color = '';
        }
      }
    },
    // Поск по типу дома
    async onChangeType(type) {
      this.componentKey += 1;
      this.isCheckHouse = type.id;
    },
    save(array) {
      if (this.idType >= 1) {
        this.$store.state.isObjectLoadingId = true;
        const item = {};

        let type = '';
        this.idTypes.map(item => {
          type += `,${item.title}`;
        });
        if (type !== '') {
          type = type.substr(1);
        }

        let days = '';
        this.modelDays.map(item => {
          days += `,${item.title}`;
        });
        if (days !== '') {
          days = days.substr(1);
        }

        array.extraData.garbageType = type;
        array.extraData.days = days;
        item.schedule = `${this.timeTo} - ${this.timeFrom}`;
        item.lat = this.coordinates[0];
        item.lng = this.coordinates[1];
        item.volume = array.volume;
        item.type = array.id ? array.id : '';
        item.type = array.type;
        item.address = array.address;
        item.amount = array.amount;
        item.coverage = array.coverage;
        if (this.isCheck) {
          item.fence = array.fence;
        } else {
          item.fence = '';
        }
        item.extraData = array.extraData;
        item.square = array.square;
        item.owner = array.owner;
        item.orgn = array.orgn;
        item.owner_address = array.owner_address;
        item.property_type = array.property_type;
        item.property_address = array.property_address;
        const pr = this.$store.dispatch('loadSave', item).then(result => {
          const r = this.$router.resolve({
            name: 'index'
          });
          if (!result?.error) {
            window.location.assign(r.href);
          }
        });
      }
    },
    onDragMarker(e) {
      this.coordinates = e.get('target').geometry.getCoordinates();
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.coordinates[1]},${this.coordinates[0]}&format=json&lang=ru_RU&kind=house`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0];

          if (geo) {
            this.items.address = geo.GeoObject.name;
          }
        });
    },
    onCoordinates(item) {
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.$cityName} ${item.trim()}&format=json&lang=ru_RU&kind=house`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

          if (geo) {
            this.coordinates = geo.Point?.pos?.split(' ').reverse();
            // console.log(this.items['address'])
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep{
  .RInput__after, .RInput__clear, .RInput__slot-after {
    order: 3;
    display: flex;
    align-items: center;
    margin-left: 12px;
  }
  .RInput__slot-after {
    height: 20px;
  }
  .RBadge.arasine, .RBadge.ptelea {
    padding: 2px 8px 2px 8px;
  }
}
.button_container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1500;
  width: 100%;
  max-width: 74.7%;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgb(4 21 62 / 16%);
  padding-left: 31px;
  padding-right: 40px;
  padding-bottom: 40px;
  height: 100px;
  padding-top: 28px;
}
.button_fly{
  padding-bottom: 112px;
  .button_container{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
    height: 100px;
    padding-top: 28px;
  }
}
.time-z {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 40px;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.edit {
  &__map {
    display: block;
    width: 650px;
    height: 500px;

  }
}

.search {
  color: #3D75E4;
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.text-covid {
  display: table;

  width: calc(100% - 174px)
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

::v-deep .rir-popover__content .rir-select__content {
  border-radius: 8px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.float-left {

}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.street {
}

.street:hover {
  opacity: 1;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  margin-top: 1px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
  color: #E5E5E5;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
}

.float {

}

.header-link {
  margin-top: 21px;
  padding-bottom: 32px;
}

.time-days {
  width: calc(50% - 10px);
}

.rir-input__after {
  max-height: 200px;
}

.custom {
  width: 100%;
}

.opacity {
  opacity: 1;
  border-radius: 8px;
}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.rir-list {
  max-width: 300px;
}

.rir-list > .rir-list-item * {
  opacity: 1;
}

.disabled {
  opacity: .32 !important;
  pointer-events: none;
}
.work-form__map {
}
</style>
<style>
.rir-list>.rir-list-item * {
  opacity: 1 !important;
}
.rir-input.readonly:not(.rir-select) {
  opacity: 1 !important;
}
.fargo {
  background-color: unset;
}
</style>
