<template>
  <div
    class="picker-wrap"
    v-click-outside="clickOutside"
  >
    <div
      class="flex align-items-center"
      :class="{
        'opacity-32 readonly' : readonly
      }"
    >
      <span
        class="rocky--text mr-2 pointer"
        @click="!readonly ? (isShowContent = !isShowContent) : null"
      >{{
        dateRange
      }}</span>
      <r-icon
        fill="rocky"
        style="margin-top: 4px;"
        :class="['picker-wrap__icon', { 'picker-wrap__icon-active': isShowContent }]"
        icon="arrow-down"
        size="8"
      />
    </div>

    <div
      class="flex content"
      v-show="isShowContent"
    >
      <r-date-picker
        class="flex-1"
        click-close
        label="С"
        v-model="dateFrom"
        @input="select"
      />
      <r-date-picker
        class="ml-2 flex-1"
        click-close
        label="По"
        v-model="dateTo"
        @input="select"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropdownDatepicker',
  directives: {
    ClickOutside
  },
  props: {
    readonly: {
      type: Boolean,
      default: true
    },
    dateFrom: {
      type: String,
      default: null
    },
    dateTo: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      filterStartDate: null,
      filterEndDate: null,
      isShowContent: false
    };
  },
  computed: {
    dateRange() {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' };
      if (this.dateFrom && this.dateTo) {
        const start = new Date(this.dateFrom).toLocaleString('Ru-ru', options).split(',')[0];
        const end = new Date(this.dateTo).toLocaleString('Ru-ru', options).split(',')[0];
        return `${start} - ${end}`;
      } if (this.dateFrom) {
        return new Date(this.dateFrom).toLocaleString('Ru-ru', options).split(',')[0];
      }
      return 'Период';
    }
  },

  methods: {
    select() {
      this.$emit('select', {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo
      });
    },
    // TODO:
    clickOutside(e) {
      // console.log('outt', e.target);
      if (this.isShowContent && e.target.closest('.rir-date-picker__content') === null) {
        this.isShowContent = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.picker-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    height: 4px;
    transition: transform 0.2s ease;
    cursor: pointer;

    &-active {
      transform: rotate(180deg);
      height: 4px;
    }
  }
}

.content {
  position: absolute;
  display: flex;
  flex-direction: row;
  background-color: #fff;

  box-shadow: 0 9px 24px rgba(17, 48, 121, 0.18);
  z-index: 10000;
  border-radius: 8px;

  min-width: 320px;
  right: 0;
  top: calc(100% + 8px);
  padding: 8px 10px;
}
.readonly{
  pointer-events: none;
}
</style>
