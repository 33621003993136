<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
          />
        </div>
        <div class="parent align-items-center">
          <div class="container-upload">
            <r-icon
              class="mr-2"
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1 class="ricotta mb-2">
              Удалить датчик из системы?
            </h1>
            <div class="mb-6 font">
              Если вы удалите датчик из системы, то доступ к нему в будущем будет невозможен
              для вас и других операторов.<br>
              Необходимо ввести логин и пароль провайдера.
            </div>
            <div class="flex mt-2 mb-8 video">
              <r-input
                :value="null"
                class="video__login"
                placeholder="Логин"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                :value="null"
                class="video__pass"
                placeholder="Пароль"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
            </div>
            <div
              class="flex mt-2"
              style="width: 100%;display: inline-block;"
            >
              <r-button
                class="flex-1"
                :disabled="true"
                title="Удалить"
              >

              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteSensor',
  data() {
    return {
      upload: false
    };
  },
  methods: {
    showSensor() {
      this.upload = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  display: inline-block;

  &__login {
    width: calc(50% - 12px);

  }

  &__pass {
    width: calc(50% - 12px);

  }
}

.align-center {
  align-items: center;

  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 43%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {

  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}
</style>
